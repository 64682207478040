<script>
	import { fly } from "svelte/transition";
	import { dbScoreOfUser } from "./database";

	let score;
	let prevScore = false;
	let doAnimation = false;
	let scoreHasLoaded = false;
	dbScoreOfUser.on("value", snap => {
		if (!snap.exists()) {
			return;
		}

		if (prevScore) {
			if (score !== snap.val() * 10) {
				doAnimation = true;
			}
		} else {
			prevScore = true;
		}
		score = snap.val() * 10;
		scoreHasLoaded = true;
	});
</script>

{#if scoreHasLoaded}
	<div class="scoreBox" in:fly={{ y: -20, duration: 1000 }}>
		<div class="outerCircle">
			<div class="innerCircle" />
		</div>
		<div class="score" class:doAnimation>
			{score}
		</div>
	</div>
{/if}

<style>
	:global(html) {
		font-size: 20px;
	}
	/* @media screen and (max-width: 1000px) {
		:global(html) {
			font-size: 18px;
		}
	}
	@media screen and (max-width: 900px) {
		:global(html) {
			font-size: 16px;
		}
	} */
	/* .scoreBox {
		position: absolute;
		top: 1rem;
		right: 1rem;
		border-radius: 1.25rem;
		background-color: #4c45ac;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.25rem;
	} */
	.scoreBox {
		position: absolute;
		top: 20px;
		right: 18px;
		border-radius: 43px;
		background-color: #4c45ac;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 7px;
	}
	.outerCircle {
		border-radius: 50%;
		background-color: #f2b746;
		padding: 0.25rem;
	}
	.innerCircle {
		padding: 0.25rem;
		border-radius: 50%;
		background-color: #fdf498;
	}
	.score {
		color: #fff;
		font-family: "Padauk";
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 0.25rem;
	}
	.doAnimation {
		animation: Hello 1s 1;
	}
	/* @keyframes Hello {
		0% {
			font-size: 1rem;
		}
		50% {
			font-size: 2rem;
		}
		100% {
			font-size: 1rem;
		}
	} */
</style>
